import * as React from "react";
import { Link } from "gatsby";
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing'

const walton = () => {
	return(
		<ModalRoutingContext.Consumer>
		{({ modal, closeTo }) => (
			<>
			  <Link 
			  	className="Modal-close Walton"
			  	to={closeTo}
			  	state={{noScroll: true }}
			  >
				<span></span>
			  </Link>
			<div className="Work-wrap Walton-modal">
				<div class="Walton-overlay"></div>
				<ul className="Work-slides">
				<li class="spacer"></li>
				<li className="Title">
					<h3 className="font-title text-center">Walton Foundation</h3>
				</li>
				<li className="Work">
					<div>
						<picture>
							<source srcset={'/work/walton/1/1-a.png'} media="(min-width: 59em)" />
							<source srcset={'/work/walton/1/1-b.png'} media="(min-width: 34em)" />
							<source srcset={'/work/walton/1/1-c.jpg'} media="(max-width: 34em)" />
							<img src={"/work/walton/1/1-a.png"} alt="Beignet Fest Work Example" />
						</picture>
					</div>
				</li>
				<li className="Work">
					<div className="Walton-video">
						<p>Example of interactive map</p>
						<video controls>
							<source src={'work/walton/walton.mp4' }type="video/mp4" />
						</video>
					</div>
				</li>
				<li className="Work Walton-final">

						<div className="walton-goto goto">
							<p>This vision of the future of the Louisiana Gulf Coast over the next fifty years was developed from the perspective of an environmental scientist with nearly a half-century of experience in the diagnosis and treatment of coastal environmental deterioration in various parts of the world.</p>
							<a target="blank" rel="noreferrer" href="https://ourfuturecoast.org"><p>View Site</p></a>
						</div>
				</li>
			</ul>
		  </div>
		  </>
		)}
	  </ModalRoutingContext.Consumer>
	)
}

export default walton